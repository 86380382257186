import React, { useContext, useState } from "react"
import tw from "twin.macro"
import { CardProps } from "./Cards.d"
import { selectionsContext } from "../Layout"
import { navigate } from "gatsby"
import Card, { CardRow } from "./Card"
import { sluggify } from "../../helpers"
import { Link } from "gatsby"
import AnalyticsEventsClient from "../../clients/AnalyticsEvents"

const Career: React.FC<CardProps> = ({ data, messages }) => {
  const { dispatch }: any = useContext(selectionsContext)
  const [optionSelected, setOptionSelected] = useState(false)
  const updateCareer = (selection: any, id: number) => {
    dispatch({ type: "UPDATE_CAREER", career: selection, careerId: id })
  }

  return (
    <div
      css={[
        tw`xl:container mx-auto w-full px-0 lg:px-24 2xl:px-44 justify-center justify-items-center bg-transparent absolute top-44 md:top-64 lg:top-96 2xl:top-96 right-0 left-0 xl:z-30`,
      ]}
    >
      <div css={[tw`text-center justify-center pb-3 lg:w-10/12 mx-auto`]}>
        <h4 css={[tw`text-white pb-5`]}>Step 1</h4>
        <h2 css={[tw`text-white leading-snug pb-5`]}>
          {messages[0].screenMessage}
        </h2>
      </div>
      <div
        css={[
          tw`relative left-0 opacity-100 transition-all ease-out duration-700`,
          optionSelected && tw`opacity-0 -left-32`,
        ]}
      >
        <CardRow>
          {data?.map((item: any, i: number) => (
            <Card
              key={item.option1Value}
              i={i}
              item={item}
              title={item.option1Value}
              image={
                messages[0].options.filter(
                  selection => selection.label == item.option1Value
                )[0].icon
              }
              onClick={() => {
                setOptionSelected(true)
                updateCareer(item.option1Value, i)
                navigate(`${sluggify(item.option1Value)}`)
                let selectedCareer = i + 1
                AnalyticsEventsClient.event({
                  eventType: "click",
                  eventName: "click_option " + selectedCareer + ".x.x",
                  eventMessage: "User selected " + item.option1Value,
                  visitorID: localStorage.getItem("visitorID")
                })
              }}
            />
          ))}
        </CardRow>
      </div>
    </div>
  )
}

export default Career
